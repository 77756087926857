//传入日期，返回 年-月-日 时：分：秒
export const FullTimeFormat = (date) => {
  if (date == "0001-01-01T00:00:00") {
    return "";
  }
  return (
    new Date(date).getFullYear() +
    "-" +
    (new Date(date).getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    new Date(date).getDate().toString().padStart(2, "0") +
    " " +
    new Date(date).getHours().toString().padStart(2, "0") +
    ":" +
    new Date(date).getMinutes().toString().padStart(2, "0") +
    ":" +
    new Date(date).getSeconds().toString().padStart(2, "0")
  );
};

//传入用户完整的手机号，返回 前三位+****+后四位
export const PhoneNumberFormat = (phone) => {
  if (phone !== "" && phone !== null) {
    return phone.slice(0, 3) + "****" + phone.slice(7);
  }

  return phone;
};
