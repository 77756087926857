import $http from "./index";
import qs from "qs";

import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;

//用户登录
export const login = (data) => {
  return $http.post(
    `/connect/token`,
    qs.stringify({
      data,
    })
  );
};
//获取Banner列表
export const getBannerList = (data) => {
  return $http.get(`/app/banners/banner/back/pagelist`, {
    params: data,
  });
};
//获取一条Banner详情
export const getBannerById = (data) => {
  return $http.get(`/app/banners/banner/back/getinfo`, {
    params: data,
  });
};
export const delBanner = (data) => {
  return $http.get(`/app/banners/banner/back/delete`, {
    params: data,
  });
};
//修改
export const updateBanner = (data) => {
  return $http.post(`/app/banners/banner/back/update`, data);
};

//新增
export const addBanner = (data) => {
  return $http.post(`/app/banners/banner/back/create`, data);
};

export const setBannerDescPic = (data) => {
  return $http.post(`/app/banners/banner/back/setdescpic`, data);
};
export const getBannerImg = (data) => {
  return $http.get(`/app/banners/banner/back/getdetailimg`, {
    params: data,
  });
};

//获取抽奖规则配置列表
export const getLotteryRuleList = (data) => {
  return $http.get(`/app/business/LotteryRuleConfig/back/pagelist`, {
    params: data,
  });
};
//获取一条抽奖规则配置详情
export const getLotteryRuleById = (data) => {
  return $http.get(`/app/business/LotteryRuleConfig/back/getinfo`, {
    params: data,
  });
};
//新增抽奖规则配置
export const addLotteryRule = (data) => {
  return $http.post(`/app/business/LotteryRuleConfig/back/new`, data);
};
//修改一条抽奖规则配置
export const updateLotteryRule = (data) => {
  return $http.post(`/app/business/LotteryRuleConfig/back/update`, data);
};
//删除一条抽奖规则配置
export const delLotteryRule = (data) => {
  return $http.get(`/app/business/LotteryRuleConfig/back/delete`, {
    params: data,
  });
};
export const getvip = () => {
  return $http.get(`/app/info/vipmanage/back/getVip`, {});
};

//获取城市列表
export const getCityList = (data) => {
  return $http.get(`/app/info/city/back/getlist`, {
    params: data,
  });
};
export const getShopList = (data) => {
  return $http.get(`/app/info/shop/back/getlist`, {
    params: data,
  });
};
export const PageShopList = (data) => {
  return $http.post(`/app/info/shop/back/pagelist`, data);
};
export const getShopById = (data) => {
  return $http.get(`/app/info/shop/back/getinfo`, {
    params: data,
  });
};
export const updateShop = (data) => {
  return $http.post(`/app/info/shop/back/update`, data);
};
export const UploadHeadImg = (data) => {
  return $http.post(`/app/info/shop/back/UploadHeadImg`, data);
};

//获取订单列表
export const getorderlist = (data) => {
  return $http.post(`/app/business/order/back/orderlist`, data);
};
export const getorderdetail = (data) => {
  return $http.get(`/app/business/order/back/get-order-detail`, {
    params: data,
  });
};

//获取后台用户列表
export const getBackUserListPage = (data) => {
  return $http.get(`/app/info/backuser/backuser/get-backuser-list`, {
    params: data,
  });
};

//根据id 获取一条后台用户的信息
export const getBackUserById = (data) => {
  return $http.get(`/app/info/backuser/backuser/get-backuser`, {
    params: data,
  });
};

//修改
export const updateBackUser = (data) => {
  return $http.post(`/app/info/backuser/backuser/update`, data);
};

//新增
export const addBackUser = (data) => {
  return $http.post(`/app/info/backuser/backuser/create`, data);
};

//删除
export const delBackUser = (data) => {
  return $http.post(`/app/info/backuser/backuser/del`, data);
};

//用户名是否重复，true就是重复
export const repeatUserName = (data) => {
  return $http.get(`/app/info/backuser/backuser/repeat-userName`, {
    params: data,
  });
};

//获取商品分类列表
export const getCategoryListPage = (data) => {
  return $http.get(`/app/business/category/back/pagelist`, {
    params: data,
  });
};

//根据id 获取一条商品分类的信息
export const getCategoryById = (id) => {
  return $http.get(`/app/business/category/back/detail?id=` + id, {});
};
//根据id 获取一条商品分类的信息
export const delCategory = (data) => {
  return $http.get(`/app/business/category/back/delete`, {
    params: data,
  });
};
//修改
export const updateCategory = (data) => {
  return $http.post(`/app/business/category/back/update`, data);
};

//新增
export const addCategory = (data) => {
  return $http.post(`/app/business/category/back/new`, data);
};

export const getCity = (data) => {
  return $http.get(`/app/info/city/back/pagelist`, {
    params: data,
  });
};

export const setlocation = (data) => {
  return $http.post(`/app/info/city/back/setlocation`, data);
};
export const setdefault = (data) => {
  return $http.get(`/app/info/city/back/setdefault`, {
    params: data,
  });
};

///////////////设置报客服电话////////////////

//获取可客服电话
export const getServicePhoneDate = () => {
  return $http.get(`/app/info/servicephone/getall`, {});
};

//修改
export const updateServicePhoneDate = (data) => {
  return $http.post(`/app/info/servicephone/back/update`, data);
};

//获取积分优惠券数据
export const getPointsCouponsDate = () => {
  return $http.get(`/app/business/pointscoupons/getall`, {});
};
//修改积分优惠券数据
export const updatePointsCouponsDate = (data) => {
  return $http.post(`/app/business/pointscoupons/back/update`, data);
};

//城市查询 接口
export const getCityPageList = (data) => {
  return $http.get(`/app/info/city/back/pagelist`, {
    params: data,
  });
};
//设置经纬度 接口
export const getCitySetlocation = (data) => {
  return $http.post(`/app/info/city/back/setlocation`, data);
};

//订单详情
export const getOrderDetail = (data) => {
  return $http.get(`/app/business/order/back/get-order-detail`, {
    params: data,
  });
};

//产品列表
export const getProductList = (data) => {
  return $http.post(`/app/info/product/back/pagelist`, data);
};

//服务列表
export const getServiceList = (data) => {
  return $http.post(`/app/info/serviceitem/back/pagelist`, data);
};

//会员列表
export const getMemberList = (data) => {
  return $http.post(`/app/info/wxuserinfo/pagelist`, data);
};

//会员列表
export const Deletephone = (data) => {
  return $http.post(`/app/info/wxuserinfo/PhoneUpdate`, data);
};

// 获取配置列表
export const getEvalContent = (data) => {
  return $http.get(`/app/info/evaluationconfiguration/getEvalContent`, {
    params: data,
  });
};

// 修改或新增单个配置
export const updateEvalContent = (data) => {
  return $http.post(
    `/app/info/evaluationconfiguration/updateEvalContent`,
    data
  );
};

// 更新单个配置
export const onlyUpdateEvalContent = (data) => {
  return $http.post(
    "/app/info/evaluationconfiguration/onlyUpdateEvalContent",
    data
  );
};

// 新增配置
export const createEvalContent = (data) => {
  return $http.post(`/app/info/evaluationconfiguration/back/new`, data);
};

// 查询单个配置
export const getEvalDetail = (data) => {
  return $http.get(`/app/info/evaluationconfiguration/back/detail`, {
    params: data,
  });
};

export const getopenidList = (data) => {
  return $http.get(`/app/push/receive/getAllUser`, {
    params: data,
  });
};
//推送订单到主业务
export const uploadOrderToZhu = (data) => {
  return $http.get(`/app/business/order/my/uploadOrderToZhu`, {
    params: data,
  });
};
export const UpdateTermNo = (data) => {
  return $http.post(`/app/info/shop/back/UpdateTermNo`, data);
};
export const GetAllListAsync = (data) => {
  return $http.get(`/app/business/pointsexchangetype/getall`, {
    params: data,
  });
};
///积分商城查询列表
export const ListPointsExchangeType = (data) => {
  return $http.get(`/app/business/pointsexchangetype/back/pagelist`, {
    params: data,
  });
};
//新增
export const CreatePointsExchangeType = (data) => {
  return $http.post(`/app/business/pointsexchangetype/back/new`, data);
};
//根据id 删除一条商品分类的信息
export const Pointsexchangetype = (data) => {
  return $http.get(`/app/business/pointsexchangetype/back/delete`, {
    params: data,
  });
};
///修改
export const updatePointsExchangeType = (data) => {
  return $http.post(`/app/business/pointsexchangetype/back/update`, data);
};

//根据id 获取一条商品分类的信息
export const PointsExchangeTypegetCategoryById = (id) => {
  return $http.get(`/app/business/pointsexchangetype/back/detail?id=` + id, {});
};
//会员权益配置列表查询
export const vipequitylist = (data) => {
  return $http.get(`/app/info/vipequity/back/pagelist`, {
    params: data,
  });
};
//会员权益配置新增
export const vipequityadd = (data) => {
  return $http.post(`/app/info/vipequity/back/new`, data);
};
//根据id 会员权益配置删除一条的信息
export const vipequitydelete = (data) => {
  return $http.get(`/app/info/vipequity/back/delete`, {
    params: data,
  });
};

//根据id会员权益配置获取一条的信息
export const vipequityid = (id) => {
  return $http.get(`/app/info/vipequity/back/detail?id=` + id, {});
};
///修改会员权益配置一条的信息
export const vipequityupdate = (data) => {
  return $http.post(`/app/info/vipequity/back/update`, data);
};
//奖品信息配置列表查询
export const prizeconfiglist = (data) => {
  return $http.get(`/app/business/prizeconfig/back/pagelist`, {
    params: data,
  });
};
//奖品信息配置新增
export const prizeconfigadd = (data) => {
  return $http.post(`/app/business/prizeconfig/back/add`, data);
};
//根据id 奖品信息配置删除一条的信息
export const prizeconfigdelete = (data) => {
  return $http.get(`/app/business/prizeconfig/back/delete`, {
    params: data,
  });
};
//根据id奖品信息配置获取一条的信息
export const prizeconfigid = (id) => {
  return $http.get(`/app/business/prizeconfig/back/detail?id=` + id, {});
};
///修改奖品信息配置一条的信息
export const prizeconfigupdate = (data) => {
  return $http.post(`/app/business/prizeconfig/back/update`, data);
};
//奖品配置表查全部
export const GetAllprizeconfig = (data) => {
  return $http.get(`/app/business/prizeconfig/getall`, {
    params: data,
  });
};

// 设置默认奖品
export const postSetDefaultPrize = (data) => {
  return $http.post("/app/business/prizeconfig/back/setDefaultPrize", data);
};

////////////////  任务管理  ///////////////////////////////////////////////
//获取子任务列表
export const getTaskList = (data) => {
  return $http.get(`/app/business/task/back/pagelist`, {
    params: data,
  });
};
//获取一条子任务详情
export const getTaskById = (data) => {
  return $http.get(`/app/business/task/back/getinfo`, {
    params: data,
  });
};
//修改子任务
export const updateTask = (data) => {
  return $http.post(`/app/business/task/back/update`, data);
};
// 新增子任务
export const addTask = (data) => {
  return $http.post(`/app/business/task/back/create`, data);
};
// 修改子任务状态
export const updateTaskActive = (data) => {
  return $http.post(`/app/business/task/back/updateTaskActive`, data);
};
export const getTotalTasklist = (data) => {
  return $http.get(`/app/business/totalTask/back/getTotalTasklist`, {
    params: data,
  });
};
//获取总任务列表
export const getTotalTaskList = (data) => {
  return $http.get(`/app/business/totalTask/back/pagelist`, {
    params: data,
  });
};
// 新增总任务
export const addTotalTask = (data) => {
  return $http.post(`/app/business/totalTask/back/create`, data);
};
// 修改总任务
export const updateTotalTask = (data) => {
  return $http.post(`/app/business/totalTask/back/update`, data);
};
//获取一条子任务详情
export const getTotalTaskById = (data) => {
  return $http.get(`/app/business/totalTask/back/getinfo`, {
    params: data,
  });
};
// 修改总任务状态
export const updateTotalTaskActive = (data) => {
  return $http.post(`/app/business/totalTask/back/updateActive`, data);
};
// 任务领取明细
export const getTaskCollectionList = (data) => {
  return $http.get(`/app/business/taskCollection/back/pagelist`, {
    params: data,
  });
};
// 抽奖信息明细
export const getDrawRecordList = (data) => {
  return $http.get(`/app/business/broadcast/back/pagelist`, {
    params: data,
  });
};
export const getCouponsList = (data) => {
  return $http.get(`/app/business/totalTask/back/getCouponslist`, {
    params: data,
  });
};

export const getCouponsLists = (data) => {
  return $http.post(`/app/info/crmInterface/getCouponsRule`, data);
};

// CRM获取会员信息
export const getMemberInfo = (data) => {
  console.log("测试接口 data ", data);
  return $http.post(
    // `/app/info/crmInterface/getMemberInfo`, // 查询会员信息  OK
    // `/app/info/crmInterface/getCustomerPoint`,// 会员交易积分增减   OK   source_log_id 商户交易号
    `/app/info/crmInterface/getCustomerGrade`, // 获取会员等级列表
    // `/app/info/crmInterface/getCustomerAccountLogs`,// 查询会员积分流水   OK
    // `/app/info/crmInterface/getCouponsRule`,// 券规则   OK
    // `/app/info/crmInterface/getCoupons`,// 会员拥有券列表  ok 空数组
    // `/app/info/crmInterface/sendCoupons`,// 发放优惠券    ok
    // `/app/info/crmInterface/cancelCoupons`,// 冲正优惠券    ok
    // `/app/info/crmInterface/getUsefulCoupons`,// 可用优惠券   空数组
    // `/app/info/crmInterface/checkCoupons`,// 用券检查      暂时不用
    // `/app/info/crmInterface/useCoupons`,// 优惠券核销    OK
    // `/app/info/crmInterface/postCustomer`,// 新增会员  OK
    // `/app/info/crmInterface/transferSubmit`,// 优惠券转赠提交
    // `/app/info/crmInterface/transferCancle`,// 取消转赠
    // `/app/info/crmInterface/getTransferCoupon`,// 转赠优惠券领取
    // `/app/info/crmInterface/getTransferCouponInfo`,// 转赠优惠券领取
    data
  );
};
